import React, { useState } from "react";

const SettingContext = React.createContext({
	logo: "",
	appName: "",
	setSetting: (setting) => {},
});

export const SettingContextProvider = (props) => {
	const [darkLogo, setDarkLogo] = useState();
	const [lightLogo, setLightLogo] = useState();
	const [appName, setAppName] = useState(null);
	const [restaurantQrUrl, setRestaurantQrUrl] = useState(null);

	const setSettingHandler = (setting) => {
		setDarkLogo(setting.dark_logo_url);
		setLightLogo(setting.light_logo_url);
		setAppName(setting.app_name);
		setRestaurantQrUrl(setting.restaurantQrUrl);
	};

	const contextValue = {
		darkLogo,
		lightLogo,
		appName,
		restaurantQrUrl,
		setSetting: setSettingHandler,
	};

	return (
		<SettingContext.Provider value={contextValue}>
			{props.children}
		</SettingContext.Provider>
	);
};

export default SettingContext;
