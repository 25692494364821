import React from "react";

const Login = React.lazy(() => import("../pages/Login"));
const Register = React.lazy(() => import("../pages/Register"));
const ForgotPassword = React.lazy(() => import("../pages/ForgotPassword"));
const NotFound = React.lazy(() => import("../pages/NotFound"));
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const Setting = React.lazy(() => import("../pages/Setting"));
const SlideShow = React.lazy(() => import("../pages/SlideShow"));
const Profile = React.lazy(() => import("../pages/Profile"));

const Operator = React.lazy(() => import("../pages/operator/Operator"));
const CreateOperator = React.lazy(() =>
	import("../pages/operator/CreateOperator")
);
const EditOperator = React.lazy(() => import("../pages/operator/EditOperator"));

const Nurse = React.lazy(() => import("../pages/nurse/Nurse"));
const CreateNurse = React.lazy(() => import("../pages/nurse/CreateNurse"));
const EditNurse = React.lazy(() => import("../pages/nurse/EditNurse"));

const Patient = React.lazy(() => import("../pages/patient/Patient"));
const CreatePatient = React.lazy(() =>
	import("../pages/patient/CreatePatient")
);
const EditPatient = React.lazy(() => import("../pages/patient/EditPatient"));

const Room = React.lazy(() => import("../pages/room/Room"));
const CreateRoom = React.lazy(() => import("../pages/room/CreateRoom"));
const EditRoom = React.lazy(() => import("../pages/room/EditRoom"));

const Facility = React.lazy(() => import("../pages/facility/Facility"));
const CreateFacility = React.lazy(() =>
	import("../pages/facility/CreateFacility")
);
const EditFacility = React.lazy(() => import("../pages/facility/EditFacility"));
const DetailFacility = React.lazy(() => import("../pages/facility/Detail"));
const CreateDetailFacility = React.lazy(() =>
	import("../pages/facility/CreateDetail")
);
const EditDetailFacility = React.lazy(() =>
	import("../pages/facility/EditDetail")
);
const FacilityGallery = React.lazy(() =>
	import("../pages/facility/FacilityGallery")
);

const Doctor = React.lazy(() => import("../pages/doctor/Doctor"));
const CreateDoctor = React.lazy(() => import("../pages/doctor/CreateDoctor"));
const EditDoctor = React.lazy(() => import("../pages/doctor/EditDoctor"));
const DoctorDetail = React.lazy(() => import("../pages/doctor/Detail"));

const VideoOnDemand = React.lazy(() =>
	import("../pages/videoOnDemand/VideoOnDemand")
);
const CreateVideoOnDemand = React.lazy(() =>
	import("../pages/videoOnDemand/CreateVideoOnDemand")
);
const EditVideoOnDemand = React.lazy(() =>
	import("../pages/videoOnDemand/EditVideoOnDemand")
);

const RestaurantMenu = React.lazy(() => import("../pages/restaurant/Menu"));
const CreateRestaurantMenu = React.lazy(() =>
	import("../pages/restaurant/CreateMenu")
);
const EditRestaurantMenu = React.lazy(() =>
	import("../pages/restaurant/EditMenu")
);

const CheckIn = React.lazy(() => import("../pages/checkIn/CheckIn"));
const CheckInHistory = React.lazy(() =>
	import("../pages/checkIn/CheckInHistory")
);
const CreateCheckIn = React.lazy(() =>
	import("../pages/checkIn/CreateCheckIn")
);
const EditCheckIn = React.lazy(() => import("../pages/checkIn/EditCheckIn"));
const CheckInDetail = React.lazy(() => import("../pages/checkIn/Detail"));
const MedicalRecord = React.lazy(() =>
	import("../pages/checkIn/MedicalRecord")
);

const OngoingOrder = React.lazy(() =>
	import("../pages/restaurant/OngoingOrder")
);
const HistoryOrder = React.lazy(() =>
	import("../pages/restaurant/HistoryOrder")
);
const OrderDetail = React.lazy(() => import("../pages/restaurant/OrderDetail"));

const Voucher = React.lazy(() => import("../pages/voucher/Voucher"));
const CreateVoucher = React.lazy(() => import("../pages/voucher/Create"));
const EditVoucher = React.lazy(() => import("../pages/voucher/Edit"));

const CreateSchedule = React.lazy(() =>
	import("../pages/schedule/CreateSchedule")
);
const EditSchedule = React.lazy(() => import("../pages/schedule/EditSchedule"));

const RunningText = React.lazy(() =>
	import("../pages/runningText/RunningText")
);

const AuthRoutes = [
	{
		path: "/dashboard",
		component: Dashboard,
		roles: ["admin", "operator", "perawat"],
	},
	{
		path: "/profile",
		component: Profile,
		roles: ["admin", "operator", "perawat"],
	},
	{ path: "/setting", component: Setting, roles: ["admin"] },
	{ path: "/slide-show", component: SlideShow, roles: ["admin"] },
	// Operator
	{ path: "/operator", component: Operator, roles: ["admin"] },
	{
		path: "/operator/create",
		component: CreateOperator,
		roles: ["admin"],
	},
	{
		path: "/operator/:user_id/edit",
		component: EditOperator,
		roles: ["admin"],
	},
	// Nurse
	{ path: "/nurse", component: Nurse, roles: ["admin", "operator"] },
	{
		path: "/nurse/create",
		component: CreateNurse,
		roles: ["admin", "operator"],
	},
	{
		path: "/nurse/:user_id/edit",
		component: EditNurse,
		roles: ["admin", "operator"],
	},
	// Patient
	{ path: "/patient", component: Patient },
	{
		path: "/patient/create",
		component: CreatePatient,
	},
	{
		path: "/patient/:patient_id/edit",
		component: EditPatient,
	},
	// Room
	{ path: "/room", component: Room },
	{
		path: "/room/create",
		component: CreateRoom,
	},
	{
		path: "/room/:room_id/edit",
		component: EditRoom,
	},
	// Facility
	{
		path: "/facility",
		component: Facility,
		roles: ["admin", "operator"],
	},
	{
		path: "/facility/create",
		component: CreateFacility,
		roles: ["admin", "operator"],
	},
	{
		path: "/facility/:facilityId/edit",
		component: EditFacility,
		roles: ["admin", "operator"],
	},
	{
		path: "/facility/:facilityId/detail",
		component: DetailFacility,
		roles: ["admin", "operator"],
	},
	{
		path: "/facility/:facilityId/create",
		component: CreateDetailFacility,
		roles: ["admin", "operator"],
	},
	{
		path: "/facility-detail/:facilityDetailId/edit",
		component: EditDetailFacility,
		roles: ["admin", "operator"],
	},
	{
		path: "/facility/:facilityId/gallery",
		component: FacilityGallery,
		roles: ["admin", "operator"],
	},
	// Doctor
	{ path: "/doctor", component: Doctor, roles: ["admin", "operator"] },
	{
		path: "/doctor/create",
		component: CreateDoctor,
		roles: ["admin", "operator"],
	},
	{
		path: "/doctor/:doctor_id",
		component: DoctorDetail,
		roles: ["admin", "operator"],
	},
	{
		path: "/doctor/:doctor_id/edit",
		component: EditDoctor,
		roles: ["admin", "operator"],
	},
	// Video on Demand
	{ path: "/vod", component: VideoOnDemand, roles: ["admin", "operator"] },
	{
		path: "/vod/create",
		component: CreateVideoOnDemand,
		roles: ["admin", "operator"],
	},
	{
		path: "/vod/:videoOnDemandId/edit",
		component: EditVideoOnDemand,
		roles: ["admin", "operator"],
	},
	// Restaurant Menu
	{
		path: "/restaurant/menu",
		component: RestaurantMenu,
		roles: ["admin", "operator"],
	},
	{
		path: "/restaurant/menu/create",
		component: CreateRestaurantMenu,
		roles: ["admin", "operator"],
	},
	{
		path: "/restaurant/menu/:menu_id/edit",
		component: EditRestaurantMenu,
		roles: ["admin", "operator"],
	},
	// Voucher
	{
		path: "/restaurant/voucher",
		component: Voucher,
		roles: ["admin", "operator"],
	},
	{
		path: "/restaurant/voucher/create",
		component: CreateVoucher,
		roles: ["admin", "operator"],
	},
	{
		path: "/restaurant/voucher/:voucherId/edit",
		component: EditVoucher,
		roles: ["admin", "operator"],
	},
	// Check In
	{
		path: "/check-in",
		component: CheckIn,
	},
	{
		path: "/check-in/history",
		component: CheckInHistory,
	},
	{
		path: "/check-in/create",
		component: CreateCheckIn,
		roles: ["admin", "operator"],
	},
	{
		path: "/check-in/:check_in_id/edit",
		component: EditCheckIn,
	},
	{
		path: "/check-in/:check_in_id",
		component: CheckInDetail,
	},
	// Medical Record
	{
		path: "/medical-record/:check_in_id/edit",
		component: MedicalRecord,
	},
	// Order
	{
		path: "/restaurant/order",
		component: OngoingOrder,
	},
	{
		path: "/restaurant/history",
		component: HistoryOrder,
	},
	{
		path: "/restaurant/order/:order_id",
		component: OrderDetail,
	},
	// Schedule
	{
		path: "/schedule/:doctor_id/create",
		component: CreateSchedule,
	},
	{
		path: "/schedule/:schedule_id/edit",
		component: EditSchedule,
	},
	// Running Text
	{
		path: "/running-text",
		component: RunningText,
	},
];

const GuestRoutes = [
	{ path: "/login", component: Login, guestOnly: true },
	{ path: "/register", component: Register, guestOnly: true },
	{ path: "/forgot-password", component: ForgotPassword, guestOnly: true },
	{ path: "/not-found", component: NotFound, guestOnly: false },
	{ path: "*", component: NotFound, guestOnly: false },
];

export { AuthRoutes, GuestRoutes };
